import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, observable } from 'aurelia-framework';
import { ConnectionType, ConnectionTypes, Coupling } from 'models/Connections';
import { Models } from 'models/core';
import { ConnectionService, ConnectionTypeService, CouplingService } from 'services';
import { ErrorService } from 'services/error-service';
import { LoopPositionHorizontalService } from 'services/loop-position-horizontal-service';
import { LoopPositionVerticalService } from 'services/loop-position-vertical-service';
import { LoopService } from 'services/loop-service';
import { LoopTypeService } from 'services/loop-type-service';
import { ToastService } from 'services/toast-service';

export class LoopFormInlineBase {
  @bindable protected loop: Models.Loop;
  @bindable protected centerBottom: boolean;
  @bindable protected netStandard: Models.NetStandard;

  protected connectionTypes: ConnectionType[] = [];

  @observable({ changeHandler: 'onSelectedCouplingIdChanged' })
  protected selectedCouplingId?: number;
  protected selectedCoupling?: Coupling;

  protected couplings: Coupling[] = [];

  constructor(
    protected loopService: LoopService,
    protected connectionService: ConnectionService,
    protected connectionTypeService: ConnectionTypeService,
    protected couplingService: CouplingService,
    protected loopPositionHorizontalService: LoopPositionHorizontalService,
    protected loopTypeService: LoopTypeService,
    protected eventAggregator: EventAggregator,
    protected toastService: ToastService,
    protected errorService: ErrorService,
    protected loopPositionVerticalService: LoopPositionVerticalService
  ) {
    void this.getConnectionTypes();
  }

  protected get is2021Standard() {
    return this.netStandard?.Version == Models.NetStandardVersion.NS9415_2021;
  }

  protected async getCouplings() {
    let res = await this.couplingService.getAllCached();
    res = res.filter((x) => !x.IsDeleted);
    this.couplings = res;
    return res;
  }

  protected onSelectedCouplingIdChanged() {
    this.selectedCoupling = this.couplings.find((c) => this.selectedCouplingId == c.Id);
  }

  protected createConnection(connectionTypeId: number) {
    return this.connectionService.post({
      ConnectionTypeId: connectionTypeId,
      Quantity: this.loop.Amount,
      NetId: this.loop.NetId,
      LoopId: this.loop.Id,
      CouplingId: this.selectedCouplingId,
    } as any);
  }

  protected updateConnection(connectionId: number) {
    return this.connectionService.put(
      {
        Quantity: +this.loop.Amount,
        CouplingId: this.selectedCouplingId,
      } as any,
      connectionId
    );
  }

  protected deleteConnection(connectionId: number) {
    return this.connectionService.delete(connectionId);
  }

  protected hasConnectionType(connectionTypeId: number) {
    return this.loop?.Connections?.find((c) => c.LoopId == this.loop.Id && c.ConnectionTypeId == connectionTypeId);
  }

  protected hasConnectionChecked() {
    if (!this.is2021Standard) return false;

    return (
      this.loop.IsAttachmentPointCage ||
      this.loop.IsAttachmentPointMageband ||
      this.loop.IsAttachmentPointCage ||
      this.loop.IsAttachmentPointPlumb
    );
  }

  async getConnectionTypes() {
    this.connectionTypes = await this.connectionTypeService.getAll();
  }

  protected async setConnections() {
    const topLoopConnectionType = this.connectionTypes.find((ct) => ct.Type === ConnectionTypes.TopLoop);
    const attachmentFloaterType = this.connectionTypes.find((ct) => ct.Type === ConnectionTypes.AttachmentFloater);
    const attachmentSinkerType = this.connectionTypes.find((ct) => ct.Type === ConnectionTypes.AttachmentSinker);
    const loopMagebandType = this.connectionTypes.find((ct) => ct.Type === ConnectionTypes.LoopMageband);

    const hasTopLoopConnection = this.hasConnectionType(topLoopConnectionType.Id);
    if (this.loop.IsAttachmentPointTopLoop) {
      if (hasTopLoopConnection) {
        await this.updateConnection(hasTopLoopConnection.Id);
      } else {
        await this.createConnection(topLoopConnectionType.Id);
      }
    } else if (!this.loop.IsAttachmentPointTopLoop && hasTopLoopConnection) {
      await this.deleteConnection(hasTopLoopConnection.Id);
    }

    const hasFloaterConnection = this.hasConnectionType(attachmentFloaterType.Id);
    if (this.loop.IsAttachmentPointCage) {
      if (hasFloaterConnection) {
        await this.updateConnection(hasFloaterConnection.Id);
      } else {
        await this.createConnection(attachmentFloaterType.Id);
      }
    } else if (!this.loop.IsAttachmentPointCage && hasFloaterConnection) {
      await this.deleteConnection(hasFloaterConnection.Id);
    }

    const hasSinkerConnection = this.hasConnectionType(attachmentSinkerType.Id);
    if (this.loop.IsAttachmentPointPlumb) {
      if (hasSinkerConnection) {
        await this.updateConnection(hasSinkerConnection.Id);
      } else {
        await this.createConnection(attachmentSinkerType.Id);
      }
    } else if (!this.loop.IsAttachmentPointPlumb && hasSinkerConnection) {
      await this.deleteConnection(hasSinkerConnection.Id);
    }

    const hasMagebandConnection = this.hasConnectionType(loopMagebandType.Id);
    if (this.loop.IsAttachmentPointMageband) {
      if (hasMagebandConnection) {
        await this.updateConnection(hasMagebandConnection.Id);
      } else {
        await this.createConnection(loopMagebandType.Id);
      }
    } else if (!this.loop.IsAttachmentPointMageband && hasMagebandConnection) {
      await this.deleteConnection(hasMagebandConnection.Id);
    }
  }
}
