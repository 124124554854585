import { AuthorizeStep } from "authorizeStep";
import { roles } from "routes";

export class Management {

  private showWeekReport: boolean = false;

  constructor() {
    if (AuthorizeStep.auth.roles.find(x => roles.leader)) {
      this.showWeekReport = true;
    }
  }

}
